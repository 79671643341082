<template>
  <div class="external-transaction-activity">
    <PageTitle title="訂閱方案" btn="新增" @btnClick="onCreate" />
    <FiltersContainer>
      <el-input
        v-model="search.name"
        clearable
        placeholder="搜尋訂閱方案"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-input
        v-model="search.equityPackName"
        clearable
        placeholder="搜尋品項組合"
        @clear="refresh(true)"
        @keypress.enter.native="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </el-input>
      <el-select
        v-model="search.hasContract"
        clearable
        placeholder="是否有合約"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <el-option
          v-for="status in subscribeContractSelectedConfig"
          :key="status.value"
          :label="status.label"
          :value="status.value"
        />
      </el-select>
    </FiltersContainer>
    <SubscribePlanTable v-loading="loading.table" :tableData="tableData" @refresh="refresh(false)" />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <CreateSubscribePlanDialog
      v-if="modal.create"
      @close="modal.create = false"
      @confirm="createSubscribePlan"
    />
  </div>
</template>

<script>
import { defineComponent, onActivated, onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
import PageTitle from '@/components/Title/PageTitle.vue'
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import SubscribePlanTable from './components/SubscribePlanTable.vue'
import { subscribeContractSelectedConfig } from '@/config/subscribe'
import { GetSubscribePlan, GetSubscribePlanCount } from '@/api/subscribe'
import CreateSubscribePlanDialog from './components/CreateSubscribePlanDialog.vue'
import { isBoolean } from 'lodash'

export default defineComponent({
  name: 'SubscribePlanList',
  components: {
    PageTitle,
    FiltersContainer,
    SubscribePlanTable,
    CreateSubscribePlanDialog,
  },
  setup (props) {
    const router = useRouter()
    const {
      extendData,
      search,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()

    extendData('search', {
      name: null,
      equityPackName: null,
      status: null,
    })
    const modal = reactive({
      create: false,
    })
    const onCreate = () => {
      // router.push({ name: 'SubscribePlanEdit' })
      modal.create = true
    }
    const createSubscribePlan = async (type, data) => {
      router.push({ name: 'SubscribePlanEdit', query: { copyId: data } })
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: search.name || undefined,
        equityPackName: search.equityPackName || undefined,
        hasContract: isBoolean(search.hasContract) ? search.hasContract : undefined,
        sortType: 'DESC',
        sortBy: 'updatedAt',
      }
      await Promise.allSettled([
        fetchData(GetSubscribePlan, payload),
        fetchDataCount(GetSubscribePlanCount, payload),
      ])
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    onMounted(() => {
      refresh()
    })
    onActivated(() => {
      refresh()
    })
    return {
      search,
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      refresh,
      onCreate,
      subscribeContractSelectedConfig,
      modal,
      createSubscribePlan,
    }
  },
})
</script>
